<template>
  <div class="monthly">
    <!-- start 条件过滤区域 -->
    <div class="df-row">
      <!-- 职位选择器 -->
      <!-- <div class="df-col">
        <el-select
          v-model="submitData.DutyNo"
          size="mini"
          placeholder="请选择职位"
        >
          <el-option
            v-for="item in dutyList"
            :key="item.DutyNo"
            :label="item.DutyName"
            :value="item.DutyNo"
          >
          </el-option>
        </el-select>
      </div> -->

      <!-- 员工选择器 -->
      <div class="df-col">
        <DfEmplSelect
          ref="DfEmplSelect"
          size="mini"
          :storesGuids="submitData.StoresGuids"
          :emplGuids="submitData.EmplGuids"
          :storeList="storeList"
          :menuMultiple="true"
          :dutyMultiple="true"
          :emplMultiple="true"
          @selected="onSelectedEmpl"
        ></DfEmplSelect>
      </div>

      <!-- 日期选择器 -->
      <div class="df-col">
        <el-date-picker
          v-model="dataArray"
          type="monthrange"
          size="mini"
          value-format="yyyy-MM"
          range-separator="至"
          start-placeholder="开始月份"
          end-placeholder="结束月份"
          @change="onChangeDate"
        >
        </el-date-picker>
      </div>

      <!-- 查询按钮 -->
      <div class="df-col">
        <el-button @click="onSearchData" size="mini" type="primary"
          >开始查询</el-button
        >
      </div>

      <!-- 导出表格按钮 -->
      <div class="df-col">
        <el-button
          @click="onExportExcel"
          size="mini"
          type="primary"
          :disabled="excelDisabled"
          >导出表格</el-button
        >
      </div>

      <!-- 打印数据按钮 -->
      <div class="df-col">
        <el-button @click="onPrintTable" size="mini" type="primary"
          >打印数据</el-button
        >
      </div>
    </div>
    <!-- end 条件过滤区域 -->

    <!-- start 员工分类月度统计报表 -->
    <div class="print">
      <el-table
        id="exprot-table"
        :data="dataList"
        v-loading="loading"
        :max-height="tableMaxHeight"
        show-summary
        stripe
        border
        fit
        size="mini"
        style="width: 100%"
      >
        <!-- <el-table-column
          v-for="(item, index) in headList"
          :key="'a' + index"
          :label="item.Name"
          width="140"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            {{ scope.row[item.DataKey] || "--" }}
          </template>
        </el-table-column> -->

        <el-table-column
          v-for="(item, index) in headList"
          :key="'a' + index"
          :label="item.Name"
          :prop="item.DataKey"
          sortable
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>
    </div>
    <!-- end 员工分类月度统计报表 -->
  </div>
</template>

<script>
import Report from "@/api/report.js";
import printJS from "print-js";

export default {
  data() {
    return {
      tableMaxHeight: "", // 表格最大高度
      authType: "", // 当前账号等级 H 总店 M 各门店
      storeList: [], // 门店员工列表
      copyStoreList: [], // 门店员工列表拷贝
      storesData: [], // 门店列表
      // typeSelector: [], // 营收类型列表
      dutyList: [], // 员工职位列表
      submitData: {
        // 提交数据
        StoresGuids: "",
        EmplGuids: "",
        DutyNo: "", // 职位
        BeginMonth: "", // 开始时间
        EndMonth: "", // 结束时间
      },
      dataArray: [], // 月份范围选择数据
      topList: [], // 一级表头数据
      headList: [], // 二级表头数据
      dataList: [], // 表格数据
      loading: false, // 表格加载
      isFixedRow: true, // 固定列
      excelDisabled: false, // 导出禁用
    };
  },

  created() {
    this.getResize();
  },

  mounted() {
    window.addEventListener("resize", this.getResize);
    let accountInfo = JSON.parse(window.sessionStorage.getItem("accountInfo"));
    this.authType = accountInfo.authType;
    this.storesData = accountInfo.manageStoresList;

    this.dataArray = [this.util.getMonth(), this.util.getMonth()];
    this.submitData.BeginMonth = this.util.getMonth();
    this.submitData.EndMonth = this.util.getMonth();

    this.initEmployeeBasicClassMonthly();
  },

  destroyed() {
    window.removeEventListener("resize", this.getResize);
  },

  methods: {
    // 获取浏览器窗口大小
    getResize() {
      let { clientHeight } = this.util.getDocumentElementInfo();
      this.tableMaxHeight = clientHeight - (100 + 40 + 72);
    },

    // 初始化页面数据
    async initEmployeeBasicClassMonthly() {
      try {
        let { data } = await Report.initEmployeeBasicClassMonthly();
        let { StoreList } = data.emplSelector;

        this.storeList = StoreList;
        this.copyStoreList = JSON.parse(JSON.stringify(StoreList));
        // this.dutyList = dutyList;
      } catch (err) {
        console.log(err);
      }
    },

    // 获取员工业绩分析数据
    async queryEmployeeBasicClassMonthly() {
      this.loading = true;
      try {
        let { data } = await Report.queryEmployeeBasicClassMonthly(
          this.submitData
        );
        let { DataList, HeadList, TopList } = data;
        this.topList = TopList;
        this.headList = HeadList;
        this.dataList = DataList;
      } catch (e) {
        //TODO handle the exception
      }
      this.loading = false;
    },

    // 时间改变事件
    onChangeDate(event) {
      this.submitData.BeginMonth = event[0];
      this.submitData.EndMonth = event[1];
    },

    // 员工选中事件
    onSelectedEmpl(event) {
      // console.log(event);
      this.storeList = event.storeList;
      this.submitData.EmplGuids = event.emplGuids;
    },

    // 查询按钮点击事件
    onSearchData() {
      this.queryEmployeeBasicClassMonthly();
    },

    // 导出表格
    onExportExcel() {
      this.excelDisabled = true;
      this.isFixedRow = false;
      this.$nextTick(() => {
        try {
          this.util.onExportExcel("#exprot-table", "员工分类业绩月度统计表");
        } finally {
          this.excelDisabled = false;
          this.isFixedRow = true;
        }
      });
    },

    // 打印数据
    onPrintTable() {
      let table = document.querySelector(".print").innerHTML;
      let div = document.createElement("div");
      div.id = "tTable";
      div.innerHTML = table;
      div.querySelector(".hidden-columns").remove();
      div.querySelector(".el-table__header-wrapper").remove();
      div.querySelector(".el-table__footer-wrapper").remove();
      div.querySelector(".el-table__fixed").remove();
      div.querySelector(".el-table__column-resize-proxy").remove();
      div.querySelector(".el-loading-mask").remove();
      let block = div.querySelector(".el-table__empty-block");
      if (block) {
        block.remove();
      }
      let tbody = div.querySelector(".el-table__body-wrapper table tbody");

      // 表头
      let group = document.querySelector(
        "#exprot-table .el-table__header-wrapper table .is-group"
      ).innerHTML;
      let thead = document.createElement("thead");
      thead.innerHTML = group;
      thead.className = "is-group";
      // console.log(thead);

      // 表尾
      let gutter = document.querySelector(
        "#exprot-table .el-table__footer-wrapper table .has-gutter tr"
      ).innerHTML;
      let tr = document.createElement("tr");
      tr.innerHTML = gutter;
      tr.className = "el-table__row";

      tbody.before(thead);
      tbody.append(tr);

      document.querySelector(".print").appendChild(div);

      // this.isFixedRow = false;
      this.$nextTick(() => {
        printJS({
          printable: "tTable", //'exprot-table',
          type: "html",
          scanStyles: false,
          style: `table { border: 1px solid #333; } th{ border: 1px solid #333; margin: 5px 0; text-align: center; } td{ border: 1px solid #333; margin: 5px 0; text-align: center;}`, // 表格样式
        });

        div.remove();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.monthly {
  .df-row {
    display: flex;
    margin: 20px 0;
    .df-col {
      margin-right: 10px;
    }
  }

  // .el-tag {
  //   display: flex;
  //   align-items: center;
  //   .el-select__tags-text {
  //     display: inline-block;
  //     max-width: 85px;
  //     white-space: nowrap;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //   }
  // }
}

::v-deep .down-select {
  display: none !important;
}
</style>
